import SvgLogo from '@/assets/icon/CanlyLogo.svg';

type LogoProps = {
  size?: 'sm' | 'md' | 'lg' | 'xl';
};

export function CanlyLogo({ size = 'md' }: LogoProps) {
  // { width = '97px', height = '32px' }
  const { width, height } = {
    sm: { width: 'px', height: 'px' },
    md: { width: '72.61px', height: '24px' },
    lg: { width: '152px', height: '34.04px' },
    xl: { width: '181.5px', height: '60px' },
  }[size];

  return <SvgLogo width={width} height={height} viewBox="0 0 152 34.04" />;
}
